import {
  InteractionSummaryResponseModel,
  SpeakingRateByTeamResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import { Segment, useFetchSegments } from '@capturi/filters'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { t } from '@lingui/macro'
import { ColumnDefinition } from 'components/DataGrid'
import React from 'react'

import {
  TeamSegmentsData,
  useSpeakingRateTeamDataStats,
} from '../../data/useSpeakingRateDataStats'
import Breakdown from '../Breakdown'
import { formatValue } from './SpeakingRate'

type Props = {
  summaries: Segment<InteractionSummaryResponseModel>[]
  onPlaySnippets: (row: TeamSegmentsData, segmentIndex: number) => void
}

export const TeamBreakdown = React.memo<Props>(
  ({ summaries, onPlaySnippets }) => {
    const { segments, isLoading } =
      useFetchSegments<SpeakingRateByTeamResponseModel>(
        insightsAPI.getSpeakingRateStatsByTeam,
      )

    const stats = useSpeakingRateTeamDataStats(segments)

    const columns = React.useMemo(() => {
      const columns: ColumnDefinition<TeamSegmentsData>[] = [
        {
          type: 'team',
          getValue: (d) => d.team.uid,
        },
        {
          type: 'value',
          getSegmentValue: (s) => s.data?.value,
          formatValue,
          alignRight: true,
        },
        {
          type: 'progress',
          getSegmentValue: (s) => s.data?.value,
          formatValue,
          range: [0, 200],
          referenceLines: summaries.reduce<
            { value: number; color: string; label: string }[]
          >((memo, s) => {
            if (s.data != null) {
              memo.push({
                label: t`Avg. ${formatValue(s.data.speakRateAverage)}`,
                color: s.color,
                value: s.data.speakRateAverage,
              })
            }
            return memo
          }, []),
        },
        {
          type: 'dataBasis',
          getSegmentValue: (s) => {
            const { conversations = 0, conversationsTotal = 0 } = s.data ?? {}
            return {
              value: conversations,
              total: conversationsTotal,
            }
          },
        },
        {
          type: 'button',
          render: (row, segmentIndex) => (
            <PlaySnippetsButton
              label={t`View conversations`}
              onClick={() => onPlaySnippets(row, segmentIndex)}
            />
          ),
        },
      ]
      return columns
    }, [summaries, onPlaySnippets])

    return <Breakdown data={stats} columns={columns} isLoading={isLoading} />
  },
)
